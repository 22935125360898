/* INITIALIZERS + DEFAULTS
 * ============================== */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

@font-face {
  font-family: 'fake_receiptregular';
  src: url('./Styles/fake_receipt-webfont.woff2') format('woff2'),
    url('./Styles/fake_receipt-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lucon';
  src: url('./Styles/lucon-webfont.woff2') format('woff2'),
    url('./Styles/lucon-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'antonregular';
  src: url('../src/fonts/anton-regular.woff2') format('woff2'),
    url('../src/fonts/anton-regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 65%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-weight: 400;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
}

/* BASE APP
   * ============================== */
/* .App {
  
} */

.App__nav {
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 80px 20px;
  text-align: center;
}

.Product-wrapper {
  background: red;
}

.App__view-cart-wrapper2 {
  position: absolute;
  top: 30px;
  margin-left: -75px;
}

.Cart {
  position: fixed;
  top: 5vh;
  right: 5vw;
  height: 90vh;
  width: 90vw;

  /* outline: 2pt solid red; */

  display: flex;
  flex-direction: column;
  border-radius: 20px;
  transform: translateX(120%);
  transition: transform 0.5s ease-in-out;
  z-index: 999999999;

  backdrop-filter: blur(10px);
  background-color: rgb(255, 0, 0, 0.5);
}

.cart-header {
  font-family: 'dead_by_dawn_usregular';
  font-weight: normal;
  font-style: normal;
  color: red;
  font-size: 50pt;
}

.Cart--open {
  transform: translateX(0);
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.Cart__header {
  padding: 20px;
  flex: 0 0 auto;
  display: inline-block;
  font-weight: 800;
  color: black;
  background-color: aqua;
}

.Cart__line-items {
  list-style: none;
  list-style: none;
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
  height: 100%;
  margin-right: 10px;
}

.Cart__footer {
  padding: 0px 20px 0px 20px;
  flex: 0 0 auto;
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
  font-weight: 700;

  font-family: 'dead_by_dawn_usregular';
  font-weight: normal;
  color: white;
  font-size: clamp(50pt, 7vw, 100pt);
  cursor: pointer;
  text-align: center;
}

.Cart__checkout:hover {
  color: red;
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-size: 15px;
  color: white;
  font-family: sans-serif;
  font-weight: 800;
}

.Cart-info__pricing {
  float: right;
}

.button {
  background-color: white;
  color: rgb(235, 53, 173);
  font-weight: 800;
  border: none;
  font-family: fake_receiptregular;
  font-size: clamp(20pt, 4vw, 30pt);
  border-radius: 10pt;
  padding: 0px 17px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  /* background-color: #222222; */
  color: black;
}

.button:disabled {
  /* background: #bfbfbf; */
  cursor: not-allowed;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* CART
   * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 5px solid black;
  border-radius: 30px 0px 0px 30px;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
  z-index: 999999999;
}

.Cart--open {
  transform: translateX(0);
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.Cart__header {
  padding: 20px;
  /* border-bottom: 1px solid #e5e5e5; */
  flex: 0 0 auto;
  display: inline-block;
  /* font-size: clamp(20pt, 10vw, 60pt); */
  /* font-size: 70pt; */
  font-family: fake_receiptregular;
  font-weight: 800;
  color: black;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 20px;
  flex: 0 0 auto;
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
  font-weight: 700;
  /* font-size: clamp(30pt, 5vw, 35pt); */
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-size: 15px;
  font-weight: 800;
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
  font-family: fake_receiptregular;
  font-weight: 800;
}

/* LINE ITEMS
   * ============================== */

.Line-item__img {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  color: #767676;
  font-family: fake_receiptregular;
  font-weight: 800;
}

.Line-item__title {
  color: #4e5665;
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
  margin-right: 40px;
  font-family: fake_receiptregular;
  font-weight: 800;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 8px;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 25px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  /* border-left: 1px solid #767676;
  border-right: 1px solid #767676; */
  font-family: lucon;
  font-weight: 800;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
  border: 1px solid black;
  border-radius: 8px;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
   * ============================== */
.Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}

.Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

.nav-div {
  margin-top: 10px;
  position: fixed;
  transition: transform 0.2s;
}

.info-nav {
  cursor: pointer;
  transition: margin-top 0.2s;
  height: 100px;
  margin-top: 20px;
  transition: transform 0.2s;
}

.info-hovered {
  filter: invert(21%) sepia(88%) saturate(3469%) hue-rotate(305deg)
    brightness(97%) contrast(118%);
  transform: translateY(5px);
  cursor: pointer;
  transition: margin-top 0.2s;
  height: 100px;
  margin-top: 20px;
  transition: transform 0.2s;
}

.nav-text {
  font-size: clamp(16pt, 3vw, 25pt);
  font-family: fake_receiptregular;
  cursor: pointer;
  transform: opacity(0);
  line-height: 0.8;
  transition: transform 0.2s;
}

.nav-text:hover {
  transform: opacity(1);
}

.nav-div:hover {
  transition: transform 0.2s;
}

@media only screen and (max-width: 600px) {
  .button-left {
    position: absolute;
    top: 48vh;
    left: 13%;
  }

  .button-right {
    position: absolute;
    top: 48vh;
    right: 15%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .button-left {
    position: absolute;
    top: 48vh;
    left: 18%;
  }

  .button-right {
    position: absolute;
    top: 48vh;
    right: 20%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .button-left {
    position: absolute;
    top: 48vh;
    left: 27%;
  }

  .button-right {
    position: absolute;
    top: 48vh;
    right: 28%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .button-left {
    position: absolute;
    top: 48vh;
    left: 33%;
  }

  .button-right {
    position: absolute;
    top: 48vh;
    right: 33%;
  }
}

@media only screen and (min-width: 1200px) {
  .button-left {
    position: absolute;
    top: 48vh;
    left: 36%;
  }

  .button-right {
    position: absolute;
    top: 48vh;
    right: 36%;
  }
}

.but {
  background: none;
  border: none;
  text-decoration: none;
  font-size: large;
  font-family: lucon;
  font-weight: 800;
  color: black;
}

.but:hover {
  color: red;
  text-decoration: none;
}

.centered-text {
  position: absolute;
  text-align: center;
  font-size: 24px; /* Adjust the font size as needed */
}

.button-modal {
  background: none;
  border: none;
  font-size: 13pt;
  color: black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.font-subscribe {
  font-family: lucon;
  font-size: 10pt;
  color: black;
}

@media only screen and (max-width: 768px) {
  .model-size {
    height: 70vh;
    width: 90vw;
    top: 15vh;
    left: 5vw;
  }
  .tickets-font {
    font-size: 30vw;
    font-family: antonregular;
  }
  .transform-mobile {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
  }
  .padding {
    padding: 0px;
  }
  .text-modal {
    font-family: lucon;
    font-size: 11pt;
    line-height: 1;
    color: black;
    max-width: 325px;
  }
  .text-modal {
    font-family: lucon;
    font-size: 11pt;
    line-height: 1;
    color: black;
    max-width: 325px;
  }
}

@media only screen and (min-width: 769px) {
  .model-size {
    height: 70vh;
    width: 60vw;
    top: 15vh;
    left: 20vw;
  }
  .tickets-font {
    font-family: antonregular;
    font-size: 18vw;
  }
  .transform-mobile {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .padding {
    padding: 30px;
  }
  .text-modal {
    font-family: lucon;
    font-size: 15pt;
    line-height: 1;
    color: black;
    max-width: 500px;
  }
}

.text-bottom-modal {
  color: black;
  position: absolute;
  bottom: 0;
  .text-modal {
    font-family: lucon;
    font-size: 15pt;
    line-height: 1;
    color: black;
    max-width: 500px;
  }
}

.text-bottom-modal {
  color: black;
  position: absolute;
  bottom: 0;
}

.div-home {
  width: 100vw;
  height: 100vh;
  vertical-align: middle;
  position: relative;
  display: flex;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.center-image {
  object-fit: contain;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 100%;
  cursor: pointer;
}

.home-container {
  width: 100vw;
  height: 100vh;
  vertical-align: middle;
  position: relative;
  display: flex;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.product-new-title {
  font-family: lucon;
  font-size: 13pt;
  line-height: 0.5;
  margin-top: 5px;
  margin-top: 5px;
}

.Prod-price-font {
  font-family: lucon;
  font-size: 13pt;
  font-weight: 200;
}

.Prod-cart {
  font-size: 12pt;
  font-family: lucon;
  background: black;
  color: white;
  padding: 0pt 2pt 0pt 2pt;
  border-radius: 5px;
  margin: auto;
}

.Prod-cart:hover {
  color: #ff09b1;
}

.Prod-font-size {
  font-size: 13pt;
  font-family: lucon;
}

.Product__description {
  list-style-type: none;
  font-family: lucon;
  font-size: 8pt;
  background-color: yellow;
  text-align: center;
}

.cart-text-new {
  font-family: lucon;
  font-size: 8pt;
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 15px;
  display: inline-block;
  margin: 4px;
}

.dot-div {
  bottom: 0;
  text-align: center;
  padding: 10px;
  margin-top: -15px;
}

@media only screen and (max-width: 768px) {
  .new-cart-div {
    height: 70vh;
    width: 90vw;
    top: 15vh;
    left: 5vw;
    position: fixed;
    border: 1.5px solid black;
    background: white;
    z-index: 9999999999;
  }
  .Line-item__title-new {
    font-size: 10px;
    font-family: lucon;
  }
  .quant-text {
    display: none;
  }
  .images-product-bind {
    position: relative;
    overflow: hidden;
    /* height: 100%; */
    display: flex;
    touch-action: none;
    max-width: 250px;
    margin: auto;
  }
  .content-box {
    border: 1.5px solid black;
    width: 100%;
    height: 150px;
    overflow: scroll;
  }
  .images-product-bind {
    position: relative;
    overflow: hidden;
    /* height: 100%; */
    display: flex;
    touch-action: none;
    max-width: 250px;
    margin: auto;
  }
  .content-box {
    border: 1.5px solid black;
    width: 100%;
    height: 150px;
    overflow: scroll;
  }
}

@media only screen and (min-width: 769px) {
  .new-cart-div {
    top: 15vh;
    left: 25vw;
    height: 70vh;
    width: 50vw;
    position: fixed;
    border: 1.5px solid black;
    background: white;
    z-index: 9999999999;
  }
  .Line-item__title-new {
    font-size: 22px;
    font-family: lucon;
  }
  .images-product-bind {
    position: relative;
    overflow: hidden;
    display: flex;
    touch-action: none;
    max-width: 500px;
    margin: auto;
  }
  .content-box {
    border: 1.5px solid black;
    width: 100%;
    height: 250px;
    overflow: scroll;
  }
}

.box-content {
  margin: 75px 25px 25px 25px;
  height: calc(100% - 120px);
  position: relative;
}

.cart-text {
  font-size: 20px;
  font-family: lucon;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 25px;
  line-height: 16px;
  font-family: fake_receiptregular;
  font-weight: 800;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-left: 30px;
}

.text-amount-cart {
  font-family: lucon;
  display: flex;
  padding: 15px 30px 0px 30px;
}

.text-amount-size {
  font-size: 16pt;
  font-size: 16pt;
}

.new-cart-checkout {
  background-color: black;
  color: white;
  border: none;
  bottom: 0;
  margin: auto;
  font-family: lucon;
  font-size: 20px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  margin-top: 15px;
}

.bg-cart {
  z-index: 9999;
  background: none;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
}

.bg-info {
  z-index: 9999;
  background: none;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
}

.box-inside-design {
  padding: 6px;
  border: 1px solid black;
}

.new-footer-text {
  color: #999;
  font-family: lucon;
  margin: auto;
}

.img-drag {
  object-fit: contain;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
}

.container-dot-two {
  position: relative;
  overflow: hidden;
  /* height: 100%; */
  display: flex;
  width: 100%;
  height: 20px;
}

.container-dot-two {
  position: relative;
  overflow: hidden;
  /* height: 100%; */
  display: flex;
  width: 100%;
  height: 20px;
}

.container-dot {
  text-align: center;
  width: 100%;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .mobile-hiding {
    display: none;
  }

  .lineitem-item-text {
    font-size: 1.1rem;
  }
  .close-cart-text {
    background: none;
    border: none;
    font-size: 20px;
    position: absolute;
    color: black;
    margin-left: 5px;
    cursor: pointer;
  }
  .Line-item__remove-new {
    background: none;
    border: none;
    font-size: 15px;
    position: absolute;
    color: black;
    top: 0px;
    left: 3px;
    cursor: pointer;
  }
  .Line-item {
    overflow: hidden;
    backface-visibility: visible;
    position: relative;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    list-style: none;
    padding: 5px;
  }

  .mobile-hiding-heloeleleoeoe {
    display: none;
  }

  .mobile-hiding-images {
    display: none;
  }
  .desktop-hiding-images {
    display: flex;
  }
  .shipping-calculated-text {
    display: none;
  }

  .product-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }

  .bottom-mobile-content {
    order: 2;
    margin-top: auto;
    padding-bottom: 50px;
  }

  .button-mobile {
    width: 100%;
  }

  .details-font {
    font-size: 18px;
    padding-top: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-hiding-heloeleleoeoe {
    display: flex;
  }

  .product-details-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lineitem-item-text {
    font-size: 1.5rem;
  }
  .close-cart-text {
    background: none;
    border: none;
    font-size: 20px;
    margin-left: 10px;
    position: absolute;
    color: black;
    cursor: pointer;
  }
  .Line-item__remove-new {
    background: none;
    border: none;
    font-size: 20px;
    position: absolute;
    color: black;
    top: 0px;
    left: 0px;
    cursor: pointer;
  }
  .Line-item {
    overflow: hidden;
    backface-visibility: visible;
    position: relative;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    list-style: none;
    padding: 10px;
  }

  .desktop-hiding-images {
    display: none;
  }

  .details-font {
    font-size: 20px;
    padding-top: 15px;
  }
}

.lineItem-image-new {
  width: 100%;
  padding: 5px;
}

.prodQuantity-update {
  background: none;
  border: none;
  width: 50px;
}

.product-image-hover {
  border: 1px black solid;
}

.product-image-hover:hover {
  border: 2px black solid;
}

.add-to-cart-outofstock {
  background: grey;
  color: white;
}

.add-to-cart-button {
  background: black;
  color: white;
  border: 1px white solid;
}

.add-to-cart-button:hover {
  background: transparent;
  color: black;
  border: 1px black solid;
}
