.return-header {
    font-family: fake_receiptregular;
    font-weight: 100;
    color: black;
    font-size: clamp(20pt, 5vw, 50pt)
}

.return-copy{
    font-size: clamp(8pt, 2vw, 10pt);
    font-family: sans-serif;
    font-weight: 100;
}